import React, { useState } from 'react'
import { images } from '../../assets/images/images'
import Swal from 'sweetalert2';
import emailjs from 'emailjs-com';
emailjs.init("uENne41d2xJw4d1mb");

const ContactUsHeroSection = () => {

    const [error, setError] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData({ ...formData, [name]: value });
  
    if (name === "email") {
      if (validateEmail(value)) {
        setError(""); 
      } else {
        setError("Please enter a valid email address.");
      }
    }
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!validateEmail(formData.email)) {
      setError("Please enter a valid email address.");
      Swal.fire({
        icon: 'error',
        title: 'Invalid Email',
        text: 'Please enter a valid email address.',
      });
      return;
    }
  
    const formWithFullName = {
      name: `${formData.firstName} ${formData.lastName}`,
      email: formData.email,
      message: formData.message,
    };
  
    emailjs
      .send("service_36kvrtb", "template_qo8es3i", formWithFullName)
      .then((result) => {
        console.log("Email successfully sent!", result.text);
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: 'Your message has been sent successfully!',
        });
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        Swal.fire({
          icon: 'error',
          title: 'Email Not Sent',
          text: 'Failed to send email. Please try again later.',
        });
      });
  };

  return (
    <section className='w-full h-[100%] my-[5rem]'>
      <div className='w-full h-[20%] flex flex-col items-center justify-center lg:my-5'>
        <h1 className="text-4xl font-bold text-center">CONTACT US</h1>
        <hr className="w-[30%] sm:w-[16%] lg:w-[5%] h-1 my-2 bg-gray-400 border-0"></hr>
      </div>
      <div className='w-full h-[80%] flex flex-col items-center justify-center lg:flex-row'>
        <div className='w-[80%] lg:w-[50%] h-[100%] md:h-[500px] flex flex-col items-center justify-center'>
            <div className='w-[90%] md:w-[80%] h-[40%] text-center py-5 md:py-10'>
                <p className='font-bold text-[25px] mb-7'>Reach out with any questions you have.</p>
                <p className='xs:text-[15px] text-[18px] md:text-[20px]'>We’re here to help and look forward to hearing from you.</p>
            </div>
            <div className='w-full h-[60%] flex justify-center items-center flex-col sm:flex-row'>
                <div className="bg-[#1f2732] w-[13rem] h-[14rem] flex flex-col items-center justify-center text-white rounded-[20px] shadow-black shadow-2xl mx-7">
                    <div className="h-[5rem] w-[5rem] rounded-[50%] bg-[#d9d9d9] flex justify-center items-center mb-5">
                        <div className="h-[4rem] w-[4rem] rounded-[50%] bg-[#d9d9d9] border-[3px] border-[#1f2732] flex items-center justify-center">
                            <img src={images.GetinTouchCall} alt='Phone-icon' className="w-[2rem] h-[2rem]" />
                        </div>
                    </div>
                    <p className="text-2xl font-medium text-center"> Call Us </p>
                    <p className="text-sm font-normal text-center"> +94 77 733 3410 </p>
                </div>
                <div className="bg-[#1f2732] w-[13rem] h-[14rem] flex flex-col items-center justify-center text-white rounded-[20px] shadow-black shadow-2xl mx-7 mt-5 sm:mt-0">
                    <div className="h-[5rem] w-[5rem] rounded-[50%] bg-[#d9d9d9] flex justify-center items-center mb-5">
                        <div className="h-[4rem] w-[4rem] rounded-[50%] bg-[#d9d9d9] border-[3px] border-[#1f2732] flex items-center justify-center">
                            <img src={images.GetinTouchPen} alt='Pen-icon' className="w-[2rem] h-[2rem]" />
                        </div>
                    </div>
                    <p className="text-2xl font-medium text-center"> Write Us </p>
                    <p className="text-sm font-normal text-center"> info@enigmasolutions.biz </p>
                </div>
            </div>
        </div>
        <div className='w-[80%] lg:w-[50%] h-[450px] lg:h-[500px] mt-10 lg:mt-0 lg:px-10 2xl:px-[5rem]'>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:mt-9">
                    <div>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className="w-full px-4 py-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f2732] shadow-gray-500 shadow-2xl"
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className="w-full px-4 py-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f2732] shadow-gray-500 shadow-2xl"
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="mt-4 lg:mt-8">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        className="w-full px-4 py-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f2732] shadow-gray-500 shadow-2xl"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {error && <p style={{ color: "red" }}>{error}</p>}
                </div>
                <div className="mt-4 lg:mt-8">
                    <textarea
                        id="message"
                        name="message"
                        rows="4"
                        className="w-full px-4 py-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#1f2732] shadow-gray-500 shadow-2xl"
                        placeholder="Enter your message"
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="mt-6 lg:mt-9">
                    <button
                        type="submit"
                        className="w-full bg-[#1f2732] text-white font-bold py-2 lg:py-4 px-4 rounded-lg hover:bg-[#344052] focus:outline-none focus:ring-2 focus:ring-teal-500">
                        Submit
                    </button>
                </div>
            </form>
        </div>
      </div>
    </section>
  )
}

export default ContactUsHeroSection

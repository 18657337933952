import React from 'react'
import { images } from '../../assets/images/images'

const SocialMediaIconsSection = () => {
  return (
    <section className='w-full h-[600px] flex flex-col items-center justify-center'>
      <div className="flex justify-center w-full xl:h-[6rem]">
        <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
      <div className='xs:h-[60%] w-[80%] h-[90%] rounded-2xl my-5'
        style={{
          backgroundImage: `url(${images.ContactUsBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}>
        <div className="flex flex-col items-center justify-center w-full h-full text-white bg-[#646464DE] bg-opacity-70 rounded-2xl">
  <div className="xs:w-[100%] w-[70%] md:w-[50%] lg:w-[30%] flex items-center justify-center text-center mb-5">
    <h1 className="font-bold text-white xs:text-[25px] text-[40px]">Connect Us On Social Media</h1>
  </div>

  <div className="flex items-center justify-center">
    <a 
      href="https://www.facebook.com/profile.php?id=61564455080838&mibextid=ZbWKwL" 
      target="_blank" 
      rel="noopener noreferrer"
      className="xs:w-[40px] xs:h-[40px] w-[3rem] h-[3rem] rounded-full bg-gray-400 bg-opacity-40 hover:bg-opacity-70 flex items-center justify-center mx-2 xs:mx-1 p-3"
    >
      <img src={images.FacebookIcon} alt="Facebook" />
    </a>
    <a 
      href="https://www.linkedin.com/company/enigma-solutions-pvtltd/" 
      target="_blank" 
      rel="noopener noreferrer"
      className="xs:w-[40px] xs:h-[40px] w-[3rem] h-[3rem] rounded-full bg-gray-400 bg-opacity-40 hover:bg-opacity-70 flex items-center justify-center mx-2 xs:mx-1 p-3"
    >
      <img src={images.LinkedinIcon} alt="LinkedIn" />
    </a>
  </div>
</div>

      </div>
    </section>
  )
}

export default SocialMediaIconsSection

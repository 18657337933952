const Strengths = ({ strengths = [] }) => {
    return (
        <div className="bg-white w-full h-auto py-8 px-12">
            <div className="font-customFont font-semibold text-4xl mb-6">
                Why Choose Us
            </div>
            <div className="flex justify-center">
                {strengths.length > 0 ? (
                    <ul className="w-full max-w-7xl bg-gray-200 rounded-lg shadow-lg px-8 py-2 space-y-4">
                        {strengths.map((strength, index) => (
                            <li key={index} className="list-disc">
                                <p className="text-gray-700">{strength}</p>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="text-gray-500">No strengths available</div>
                )}
            </div>
        </div>
    );
};

export default Strengths;

import MainLayout from "../components/sharedComponents/MainLayout";
import AboutUsHeroSection from "../components/AboutUsPageComponents/AboutUsHeroSection";
import OurVisionSection from "../components/AboutUsPageComponents/OurVisionSection";
import WhyChooseUsSection from "../components/AboutUsPageComponents/WhyChooseUsSection";
import EventsGallerySection from "../components/AboutUsPageComponents/EventsGallerySection";

export default function AboutUs() {
  return (
    <MainLayout>
      <main>
        <AboutUsHeroSection />
        <OurVisionSection />
        <WhyChooseUsSection />
        <EventsGallerySection />
      </main>
    </MainLayout>
  );
}

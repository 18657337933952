import React from 'react'
import { images } from '../../assets/images/images';
import Carousel from '../sharedComponents/carousel';
import FeatureCard from './Cards/FeatureCard';

const WhyChooseUsSection = () => {
    const dataArraY = [
        {
          title: "Ownership",
          image: images.Feature1Icon,
          content:
            "At Enigma Solutions, we take complete responsibility for our commitments and actions. By owning up to challenges and striving to exceed expectations, we ensure trust, accountability, and unwavering dedication to delivering exceptional results for our clients.",
        },
        {
          title: "Think Smarter & Bigger",
          image: images.Feature2Icon,
          content:
            "We embrace innovation and challenge conventional boundaries to envision transformative solutions. By thinking strategically and adapting to change, we deliver impactful results that drive progress and position businesses for long-term success.",
        },
        {
          title: "Work Ethic",
          image: images.Feature3Icon,
          content:
            "Our team embodies professionalism, precision, and persistence. Guided by a commitment to excellence, we maintain a proactive and disciplined approach, continuously refining our skills to meet the dynamic demands of the digital world.",
        },
      ];

  return (
    <section className='w-full h-[100%] flex flex-col justify-center items-center'>
        <div className="flex justify-center w-full xl:h-[8rem] ">
          <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>

        <div className='bg-[#1F2732] my-5 py-8 w-full'>
            <div className="hidden lg:flex h-[100%] w-full justify-between items-center px-[2%] 2xl:px-[4%]">
            {dataArraY.map((unit) => (
                <FeatureCard
                title={unit.title}
                picture={unit.image}
                content={unit.content}
                />
            ))}
            </div>
        
            <div className="lg:hidden w-full">
            <Carousel autoSlide={true}>
                {dataArraY.map((unit) => (
                <FeatureCard
                    title={unit.title}
                    picture={unit.image}
                    content={unit.content}
                />
                ))}
            </Carousel>
            </div>
        </div>
        
        <div className="flex justify-center w-full xl:h-[8rem] ">
          <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
    </section>
  )
}

export default WhyChooseUsSection

export default function LifeAtEnigma() {
  const cardsOptions = [
    "People Centricity",

    "Recognition & Rewards",

    "Back to Work",

    "Empowerment & Nurturing",

    "Work-Life Balance",
  ];

  return (
    <section className="w-full px-[10%] my-[2rem]   lg:flex lg:h-[54rem] xl:h-[44rem] items-center">
      <div className="w-full text-center lg:w-[50%] lg:pr-[3rem]  ">
        <h1 className="font-bold xs:text-2xl text-3xl sm:text-4xl lg:text-5xl xl:text-6xl">
          Your life at Enigma Solutions
        </h1>

        <h3 className="mt-[1rem] text-xl sm:text-2xl xl:text-3xl font-medium">
        Find your ideal job and grow with us
        </h3>

        <p className="mt-[2rem] xs:text-sm text-md sm:text-xl xl:text-2xl">
        Our success stems from our passionate and dedicated team. We prioritize building strong relationships and fostering a positive, impactful work environment.
        </p>
      </div>

      <div className="w-full my-[3rem] flex flex-col items-center lg:w-[50%]  lg:h-[54rem] xl:h-[44rem]  xl:flex-wrap xl:justify-center lg:gap-[5px] ">
        {cardsOptions.map((unit) => (
          <div className="relative xs:h-[8rem] h-[12rem] lg:h-[8rem] xl:h-[10rem] xs:w-full w-[18rem] sm:w-[22rem] xl:w-[15rem] 2xl:w-[18rem]   my-[2rem] group hover:cursor-pointer ">
            <div className="absolute z-0 xs:h-[8rem] h-[12rem] lg:h-[8rem] xl:h-[10rem] xs:w-full w-[18rem] sm:w-[22rem] xl:w-[15rem] 2xl:w-[18rem]  bg-[#1F2732] text-white font-bold flex justify-center items-center rounded-tr-[20px] rounded-bl-[20px]  xs:text-xl text-2xl text-center px-[5%] ">
              {unit}
            </div>

            <div className="absolute z-10 xs:h-[8rem] h-[12rem] lg:h-[8rem] xl:h-[10rem] w-0 bg-white opacity-10 rounded-[10px] group:hover:cursor-pointer  xs:group-hover:w-full group-hover:w-[18rem] xl:group-hover:w-[15rem] 2xl:group-hover:w-[18rem] sm:group-hover:w-[22rem] transition-all duration-1000"></div>
          </div>
        ))}
      </div>
    </section>
  );
}

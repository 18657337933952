import { useNavigate } from "react-router-dom";

export default function ServiceCard({ title, picture, content, redirectTo }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center w-full lg:w-[20rem] px-[10%] lg:px-0 mb-[1rem] h-[28rem] sm:h-[35rem] lg:h-[40rem] lg:ml-[2rem] lg:justify-center">
      <div className="w-[18rem] h-[35rem] lg:h-[40rem] lg:w-full  xs:w-auto  flex flex-col justify-between items-center ">
        <div className="h-[8rem] sm:h-[10rem] w-[8rem] sm:w-[10rem] bg-[#d9d9d9] flex justify-center items-center rounded-[50%]">
          <div className="h-[7rem] sm:h-[9rem] w-[7rem] sm:w-[9rem] bg-[#d9d9d9] flex justify-center items-center rounded-[50%] border-[5px] border-white">
            <img
              src={picture}
              alt={title}
              className="h-[4rem] w-[4rem] sm:h-[5.5rem] sm:w-[5.5rem]"
            />
          </div>
        </div>
        <span className="text-center font-medium text-3xl lg:text-2xl xl:text-3xl  my-[1rem] lg:h-[6rem] xl:h-[7rem] flex items-center">
          {title}
        </span>
        <p className="text-center mb-[1rem] text-sm sm:text-xl  font-thin">
          {content}
        </p>
        <button
          onClick={() => navigate(redirectTo)}
          className="text-[#0EAC3B] text-sm lg:text-xl border-[#0EAC3B] border-[3px] px-[1rem] py-[.5rem] rounded-[20px] hover:bg-[#0EAC3B] hover:text-white font-bold transition-all duration-700 "
        >
          Explore Now
        </button>
      </div>
    </div>
  );
}

import React, {useEffect} from "react";
import { images } from "../../assets/images/images";

const AboutUsHeroSection = () => {
  useEffect (()=>{
    window.scrollTo(0,0);
})
  return (
    <section className="w-[100%] h-screen xs:h-[100%]">
      <div className="w-full h-[30%] text-center flex items-center justify-center flex-col pt-[60px]">
        <h1 className="text-4xl font-bold">ABOUT US</h1>
        <hr className="w-[30%] sm:w-[16%] lg:w-[5%] h-1 my-2 bg-gray-400 border-0"></hr>
        <p className="text-gray-700 text-[13px] sm:text-[15px] lg:text-[18px]">
          Innovating businesses globally with over 25 years of expertise in enterprise agility, digital transformation, and IT solutions.{" "}
        </p>
      </div>
      <div className="w-full h-[70%] flex flex-col items-center justify-center mt-5">
        <div className="flex justify-center w-full xl:h-[6rem]">
          <div className="h-[4rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
        <div
          className="w-[80%] h-[70%] rounded-2xl my-5"
          style={{
            backgroundImage: `url(${images.AboutUsHeroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="w-full h-full bg-gray-500 bg-opacity-70 flex flex-col items-center text-white rounded-2xl">
            <div className="w-[90%] h-[30%] flex flex-col justify-center items-center py-4">
              <h1 className="xs:text-[16px] text-[20px] sm:text-[25px] lg:text-[27px] xl:text-[30px] font-bold text-center">
                Our Expertise at a Glance
              </h1>
            </div>
            <div className="w-[90%] sm:w-[75%] h-[80%] flex flex-col justify-center items-center text-center">
              <p className="font-bold xs:text-[10px] text-[12px] sm:text-[15px] lg:text-[17px] xl:text-[19px]">
                With a legacy of excellence, Enigma Solutions delivers cutting-edge technology solutions, strategic consulting, and advanced training. Our tailored services empower businesses to thrive in a fast-paced, digital-first world.
              </p>
              <button className="bg-[#71C25D] w-[150px] h-[45px] rounded-[16px] text-gray-700 font-bold my-5 xl:my-7">
                View Portfolio
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center w-full xl:h-[6rem]  ">
          <div className="h-[5rem] lg:h-[4rem] xl:h-[6rem] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHeroSection;

import React from 'react'

const FeatureCard = ({ title, picture, content }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-[350px] text-white">
        <div className='w-[80%] h-[30%] flex justify-center items-center mb-2'>
            <div className='w-[100px] h-[100px] xl:w-[120px] xl:h-[120px] rounded-full'>
                <img src={picture} alt={`${title} Icon`} className="w-[100px] h-[100px] rounded-full" />
            </div>
        </div>
        <div className='xs:w-[80%] w-[90%] sm:w-[80%] 2xl:w-[75%] h-[70%] py-5 xs:px-2 px-9 lg:px-0 flex flex-col justify-center items-center'>
            <div className='flex flex-col items-center justify-center h-[20%]'>
                <h3 className="text-[18px] sm:text-[22px] lg:text-[20px] xl:text-[22px] font-bold mb-2 text-center text-shadow-outline">
                    {title}
                </h3>
            </div>
                
            <div className="flex flex-col h-[80%] w-full font-semibold pt-3">
                <p className="text-center xs:text-[12px] sm:text-[18px] lg:text-[16px] xl:text-[17px] font-thin">
                    {content}
                </p>
            </div>
        </div>
    </div>
  )
}

export default FeatureCard

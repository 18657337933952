import { images } from "../../assets/images/images";
export default function RoadMap() {
  const RoadmapCard = ({
    number,
    color,
    topic,
    image,
    description,
    position,
  }) => (
    <div
      className={`${position ? "flex-row" : "flex-row-reverse"
        } sm:w-[25rem] lg:w-full lg:flex   lg:h-[15rem] lg:items-center lg:justify-between`}
    >
      <div
        className={`${position ? "lg:flex-row" : "lg:flex-row-reverse"
          } flex items-center h-[5rem] mb-[1rem] w-full lg:w-auto justify-center `}
      >
        <span
          className={` font-bold text-8xl lg:text-[10rem] `}
          style={{ color: color }}
        >
          {number}
        </span>
        <span
          className={`${position ? "lg:ml-[-4rem]" : "lg:ml-0 lg:mr-[-4rem] "
            } text-[#ffff] text-4xl sm:text-5xl font-semibold ml-[-1rem] z-20  `}
          style={{
            WebkitTextStroke: "2px #1F2732",
          }}
        >
          {topic}
        </span>
      </div>

      <div
        className={`${position
            ? "lg:ml-[1rem] lg:flex-row "
            : "lg:mr-[1rem] lg:flex-row-reverse"
          } flex flex-col items-center w-full bg-[#646464] px-[5%] sm:px-[2rem] py-[2rem] h-[18rem] sm:h-[22rem] lg:h-[12rem] rounded-[20px]  `}
        style={{ border: `2px solid ${color}` }}
      >
        <img
          src={image}
          alt="strategies"
          className="w-[6rem] h-[6rem] sm:h-[8rem] sm:w-[8rem] lg:h-[7rem] lg:w-[7rem]"
        />
        <p
          className={`${position
              ? "lg:ml-[1rem] xl:ml-[3rem] lg:text-left"
              : "lg:mr-[1rem] xl:mr-[3rem] lg:text-right"
            } text-[#ffff] h-[12rem] flex items-center leading-snug text-sm sm:text-[18px] lg:text-[17px] 2xl:text-[22px] text-center mt-[1rem] lg:mt-0`}
        >
          {description}
        </p>
      </div>
    </div>
  );

  return (
    <section className="bg-[#1F2732] px-[10%] pb-[3rem]  ">
      <div className="flex justify-center w-full xl:h-[8rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px] "></div>
      </div>
      <div className="w-full flex flex-col items-center lg:my-[2rem]">
        <RoadmapCard
          number="01"
          color="#ff9900"
          topic="Strategy"
          image={images.RoadmapStrategies}
          position={true}
          description="We analyze business goals and user needs to craft a roadmap aligned with tailored solutions, IT automation, and data-driven strategies. "
        />
        <div className="lg:flex lg:w-full justify-start">
          <img
            src={images.RoadmapFirstArrow}
            alt=""
            className="h-[6rem] sm:h-[10rem]  mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>

        <RoadmapCard
          number="02"
          color="#ff0de5"
          topic="Design"
          image={images.RoadmapDesign}
          position={false}
          description="We create intuitive designs and scalable systems that blend aesthetics, functionality, and performance."
        />
        <div className="lg:flex lg:w-full justify-end">
          <img
            src={images.RoadmapSecondArrow}
            alt=""
            className="h-[6rem] sm:h-[10rem] mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>
        <RoadmapCard
          number="03"
          color="#00FFF0"
          topic="Develop"
          image={images.RoadmapDevelop}
          position={true}
          description="Using Agile methodology, we build custom solutions, automate workflows, and implement advanced data systems for seamless functionality."
        />
        <div className="lg:flex lg:w-full justify-start">
          <img
            src={images.RoadmapFirstArrow}
            alt=""
            className="h-[6rem] sm:h-[10rem] mt-[-2rem] lg:mt-[-4rem]"
          />
        </div>
        <RoadmapCard
          number="04"
          color="#00FF57"
          topic="Support"
          image={images.RoadmapSupport}
          position={false}
          description="We offer ongoing support to maintain, enhance, and adapt solutions to meet evolving business needs."
        />
      </div>
      <div className="flex justify-center w-full xl:h-[8rem] ">
        <div className="h-[5rem] xl:h-[8rem] xs:border-l-[3px] border-l-[4px] border-dashed border-white xs:w-[3px] w-[4px] "></div>
      </div>
    </section>
  );
}

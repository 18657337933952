import { useEffect } from "react";
import { images } from "../../assets/images/images";

export default function HeroSection({ onMouseIconClick }) {
  useEffect (()=>{
    window.scrollTo(0,0);
})
  return (
    <div className="relative">
      <div
        className="relative top-0 p-10 text-center h-[100vh] bg-cover bg-center"
        style={{
          backgroundImage: `url(${images.HeroSectionBackgroundPhoto})`,
          clipPath: "polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%)",
        }}
      ></div>

      <div
        className="absolute top-0 left-0 w-full h-[100vh] flex items-center justify-center bg-[#646464] opacity-[0.9] px-[10%] "
        style={{ clipPath: "polygon(0 0, 100% 0, 100% 85%, 50% 100%, 0 85%)" }}
      >
        <div className="text-white font-bold text-center">
          <span className="xs:text-xl text-2xl sm:text-3xl xl:text-4xl">
            Welcome to
          </span>
          <h1
            className="xs:text-5xl text-6xl sm:text-7xl xl:text-8xl my-[2rem]"
            style={{
              textShadow:
                "-1px -1px 0 black,  1px -1px 0 black, 1px  1px 0 black,-1px  1px 0 black",
            }}
          >
            Enigma Solutions
          </h1>
          <span className="xs:text-2xl text-3xl sm:text-4xl xl:text-5xl">
            Innovative Design, Development & Maintenance
          </span>
        </div>
        <div className="absolute bottom-0 w-full flex justify-center pb-[1rem]">
          <img
            src={images.MouseIcon}
            alt=""
            className="xs:h-[2rem] h-[4rem] xl:h-[5rem] hover:cursor-pointer"
            onClick={onMouseIconClick}
          />
        </div>
      </div>
    </div>
  );
}

import React from 'react'
import { images } from '../../assets/images/images';
import Carousel from '../sharedComponents/carousel';

const EventsGallerySection = () => {

    const eventImages = [
        { image: images.EventImage },
        { image: images.EventImage },
        { image: images.EventImage },
        { image: images.EventImage },
      ];

  return (
    <section className='w-full h-[100%] px-[7%] my-10'>
        <div className='w-full h-[20%] text-center my-5 md:my-7 2xl:my-10'>
            <h1 className='font-bold text-[25px] mb-2 2xl:mb-7'>Life at Enigma Solutions</h1>
            <p className='text-[12px] md:text-[15px]'>At Enigma Solutions, we thrive on creativity and innovation, with our team constantly thinking outside the box to deliver fresh, impactful solutions. Every project is approached with a new vision, and our collaborative environment fosters unique ideas that drive success. Explore our gallery to see the moments that capture our team’s spirit and commitment to excellence.</p>
        </div>
        <div className="hidden sm:grid sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 px-4">
            {eventImages.map((item, index) => (
                <div
                    key={index}
                    className="w-full h-64 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
                    <img src={item.image} alt={`Event ${index + 1}`} className="w-full h-full object-contain"/>
                </div>
            ))}
        </div>

        <div className="sm:hidden w-full">
            <Carousel autoSlide={true}>
                {eventImages.map((item, index) => (
                    <div
                        key={index}
                        className="w-full h-64 overflow-hidden rounded-lg shadow-lg flex justify-center items-center">
                        <img src={item.image} alt={`Event ${index + 1}`} className="w-full h-full object-contain"/>
                    </div>
                ))}
            </Carousel>
        </div>
    </section>
  )
}

export default EventsGallerySection

import { useNavigate } from 'react-router-dom';

const Header = ({ name, description, image }) => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between py-[4rem] md:py-[6rem] md:space-x-0.5">
            <div className="text-center justify-center
                            space-y-12 py-4 md:py-12
            ">
                <div className="space-y-8 px-12">
                    <h2 className="font-bold text-5xl">{name}</h2>
                    <p className="font-light text-3xl">{description}</p>
                </div>
                <div>
                    <button onClick={() => navigate('/contact')} type="button" class="w-36 h-12 bg-white text-customGreen border-2 border-solid border-customGreen drop-shadow-2xl hover:drop-shadow-xl focus:ring-4 focus:outline-none focus:ring-customGreen font-medium rounded-full text-lg text-center me-2 mb-2 dark:text-customGreen dark:hover:text-white dark:hover:bg-customGreen dark:focus:ring-customGreen">
                        More Details
                    </button>
                </div>
            </div>
            <div className="relative hidden sm:block md:py-4 lg:py-4 xl:py-4 lg:px-32 xl:px-32 2xl:px-32">
                <div>
                    <svg
                        className="w-64 h-60 md:w-72 md:h-68 lg:w-92 lg:h-76 xl:w-92 xl:h-96 2xl:w-92 2xl:h-96"
                        viewBox="0 0 518 491"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g filter="url(#filter0_d_1_629)">
                            <path d="M514 315.69C514 456.522 371.332 482.69 230.5 482.69C89.6672 482.69 4 456.522 4 315.69C4 174.857 3.9998 -129.311 259 60.6895C514 250.689 514 174.857 514 315.69Z" fill="#1F2732" fill-opacity="0.7" shape-rendering="crispEdges" />
                        </g>
                        <defs>
                            <filter id="filter0_d_1_629" x="0" y="0" width="518" height="490.689" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_629" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_629" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <img
                        className="w-auto h-auto md:w-72 md:h-68 lg:w-96 lg:h-76"
                        src={image}
                        alt={name}
                    />
                </div>
            </div>


        </div>
    )
}

export default Header;
import { useEffect } from "react";
import { images } from "../../assets/images/images";

export default function CareerHeroSection({ onSeeOpeningsClick }) {
  useEffect (()=>{
    window.scrollTo(0,0);
})
  return (
    <section className="h-[100vh] ">
      <div className="lg:flex lg:h-[80vh] lg:pt-[20vh] lg:w-full lg:px-[10%]">
        <div className="flex flex-col  xs:h-[60vh] h-[50vh] lg:h-[60vh] pt-[15vh] lg:pt-0 items-center justify-center  w-full lg:w-[50%] px-[10%] lg:px-[3%] ">
          <h className="font-semibold lg:font-medium xs:text-xl text-4xl sm:text-5xl xl:text-6xl 2xl:text-7xl text-center mb-[1rem] ">
            Join our team at Enigma Solutions
          </h>
          <button
            onClick={onSeeOpeningsClick}
            className="w-[10rem] h-[3rem] xl:h-[4rem] xl:w-[12rem] bg-white border-[2px] border-[#71C25D] text-[#71C25D] xs:text-[14px] text-[16px] sm:text-[18px] xl:text-[21px] font-bold mt-[1rem] rounded-[15px] hover:bg-[#71C25D] hover:text-white transition-all duration-700"
          >
            See Openings
          </button>
        </div>

        <div className="xs:h-[30vh] h-[40vh] lg:h-[60vh] px-[10%] lg:px-0 lg:w-[50%] lg:flex items-center">
          <img
            src={images.CareerHeroPicture}
            alt=""
            className="rounded-[5px] xs:h-[30vh] h-[40vh] w-[100%] object-cover shadow-md shadow-black"
          />
        </div>
      </div>

      <div className="flex justify-center w-full h-[8vh] lg:h-[20vh] absolute mt-[2vh] ">
        <div className="h-[8vh] lg:h-[20vh] xs:border-l-[3px] border-l-[4px] border-dashed border-black xs:w-[3px] w-[4px] "></div>
      </div>
    </section>
  );
}

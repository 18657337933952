import { forwardRef } from "react";
import { images } from "../../assets/images/images";

const JobSection = forwardRef((props, ref) => (
  <section ref={ref} className="px-[10%] w-full xs:h-[24rem] h-[30rem]">
    <div className="h-[7rem] w-full flex items-center justify-center">
      <h1 className="text-center xs:text-xl text-3xl sm:text-4xl xl:text-5xl font-bold">
        Our Openings
      </h1>
    </div>
    <div className="xs:h-[17rem] h-[23rem] flex justify-center items-center w-full">
      <div className="flex flex-col justify-center items-center">
        <p className="text-center xs:text-sm text-md sm:text-xl md:text-2xl xl:text-3xl mb-[2rem] font-medium">
          We currently have no job openings. Please check back later for
          updates.
        </p>
        <img
          src={images.NotAvailableImage}
          className="xs:w-[8rem] w-[13rem]"
          alt=""
        />
      </div>
    </div>
  </section>
));

export default JobSection;
